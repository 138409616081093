import { Outlet } from "react-router-dom"
import { Header } from "./Pages/BillingPage/headerPage"
import { Container, styled } from "@mui/material"

const PREFIX = 'controle-comandas'

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  body: `${PREFIX}-body`
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.body}`]: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}))

function App() {

  return (
    <Root classeName={classes.root}>
      <Header />
      <Container component="main" className={classes.body} maxWidth={false}>
        <Outlet />
      </Container>
    </Root>
  )
}

export default App
