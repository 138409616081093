const md5 = require('md5')

export default class AuthService {

    constructor() {
        this.domain = process.env.REACT_APP_API_URL
    }

    getOnlineConfigStorageKey() {
        return '_saved_config_online_'
    }

    getOfflineConfigStorageKey() {
        return '_saved_config_offline_'
    }

    getDefaultPath() {
        return 'C:/Sischef/comandas'
    }

    getProfile() {
        const savedConfig = localStorage.getItem(this.getOnlineConfigStorageKey())
        if (!savedConfig) return ''

        const savedConfigJson = JSON.parse(savedConfig)
        if (!savedConfigJson.profile) return ''

        return savedConfigJson.profile
    }

    getToken() {
        const profile = this.getProfile()

        if (!profile) return ''

        return profile.jwtToken
    }

    get(endpoint) {
        return fetch(this.domain + endpoint, {
            method: 'GET',
            body: null,
            headers: {
                'Authorization': 'Bearer ' + this.getToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            }
        }).then(response => response.json())
    }

    login(data) {
        return fetch(this.domain + '/api-v2/usuario/login', {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify({
                username: data.username,
                password: md5(data.password),
            })
        }).then(data => data.json()).then(response => {
            if (!response.jwtToken) {
                throw new Error('Erro ao realizar login - ' + response.errorMessage)
            }

            return response
        })
    }

}