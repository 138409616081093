import { Avatar, Box, Button, Grid, Typography, } from "@mui/material";
import { useContext, } from "react";
import { FunctionsContext } from "../../Context/functionsContext";
import { Wifi, WifiOff } from "@mui/icons-material";
import { Link } from "react-router-dom";

export function LogoPage() {
    const {
        conectado,
        palette,
    } = useContext(FunctionsContext)

    return (
        <Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="72vh"
                padding={3}
            >
                <Grid container direction="column" spacing={2} alignItems="center">

                    {/* Logo */}
                    <Grid item sx={{ marginBottom: 0.5 }}>
                        <Avatar
                            sx={{
                                width: 200,
                                height: 200,
                                boxShadow: 5,
                                transition: "transform 0.3s ease",
                                "&:hover": {
                                    transform: "scale(1.05)",
                                },
                            }}
                            src="../assets/logo-sischef.png"
                        />
                    </Grid>

                    {/* Status de Conexão */}
                    <Grid item sx={{ marginBottom: 1.0 }} >
                        <Typography
                            variant="h6"
                            fontWeight="bold"
                            color={conectado ? palette.success.main : palette.error.main}
                            textAlign="center"
                        >
                            {conectado ? "Conectado" : "Desconectado"}
                        </Typography>
                    </Grid>

                    {!conectado && (
                        <>
                            {/* Botão para Conectar On-line */}
                            <Grid item xs={0} display="flex" justifyContent="center" alignItems="center">
                                <Link to="/ConfgOnline" style={{ textDecoration: 'none' }}>
                                    <Button
                                        variant="contained"
                                        endIcon={<Wifi />}
                                        fullWidth
                                        sx={{
                                            width: '25rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        Conectar On-line
                                    </Button>
                                </Link>
                            </Grid>

                            {/* Botão para Conectar Off-line */}
                            <Grid item xs={0} display="flex" justifyContent="center" alignItems="center">
                                <Link to="/ConfgOffline" style={{ textDecoration: 'none' }}>
                                    <Button
                                        variant="contained"
                                        endIcon={<WifiOff />}
                                        fullWidth
                                        sx={{
                                            width: '25rem'
                                        }}
                                    >
                                        Conectar Off-line
                                    </Button>
                                </Link>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
        </Box>
    );
}
